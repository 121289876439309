input,
select {
  outline: none;
}

body {
  background: #000;
  color: #c5c5c5;
  font-size: 16px;
}

img {
  font-size: 10px;
}

@media (prefers-color-scheme: light) {
  html {
    color-scheme: dark only;
  }
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark only;
  }
}

* {
  outline: none;
}

* ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

* ::-webkit-scrollbar-track {
  background-color: rgba(94, 136, 141, 0.356);
}

* ::-webkit-scrollbar-thumb {
  background-color: #020202d5;
  border-radius: 15px;
}

* ::-webkit-scrollbar-thumb:hover {
  background-color: rgba(128, 128, 128, 0.623);
}

.scrollbar-hidden {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.thin-scrollbar::-webkit-scrollbar {
  width: 4px;
}

.thin-scrollbar::-webkit-scrollbar-track {
  background-color: #5858584f;
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  background-color: #838383ab;
  border-radius: 15px;
  /* border: 6px solid transparent; */
  /* background-clip: content-box; */
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .border-b,
  .border-t {
    @apply border-[#464647de];
  }
}

@layer components {
  .btn {
    @apply flex h-fit items-center justify-center gap-3 rounded-[0.6rem] border border-black bg-white fill-black px-6 py-2.5 text-center text-sm font-medium text-black outline-none hover:opacity-80 disabled:opacity-70 disabled:hover:bg-white [&.black]:bg-black [&.black]:text-white [&.destructive]:text-red-600 [&.lg]:rounded-xl [&.lg]:py-[0.85rem] [&.outline]:border [&.outline]:border-black [&.outline]:bg-transparent [&.outline]:text-black [&.sm]:rounded-[0.6rem] [&.sm]:px-5 [&.sm]:py-[0.45rem] [&.sm]:text-xs [&.sm]:font-semibold [&.xs]:rounded-[0.525rem] [&.xs]:py-[0.32rem];
  }
  .error {
    @apply text-center text-xs text-red-500;
  }
  .divider {
    @apply border-b border-b-neutral-200;
  }
  .btn-white {
    @apply rounded-[10px] bg-white px-4 py-1.5 text-center text-sm text-black disabled:opacity-50 md:rounded-[12px];
  }
  .btn-white-full {
    @apply mx-auto w-full rounded-[10px] bg-white py-2 text-sm font-medium text-black disabled:opacity-50 md:rounded-[12px];
  }
  .btn-dark {
    @apply mx-auto rounded-[8px] bg-white/10 px-3 py-1 text-sm font-semibold text-white disabled:opacity-50 md:rounded-[12px];
  }
  .btn-black {
    @apply rounded-[8px] bg-black px-3 py-1 text-sm font-semibold text-white disabled:opacity-50 md:rounded-[12px];
  }
  .center-x {
    @apply left-1/2 -translate-x-1/2;
  }
  .center-y {
    @apply top-1/2 -translate-y-1/2;
  }
  .hover-animate {
    @apply transition-all hover:scale-105 disabled:hover:scale-100;
  }
  .btn-full {
    @apply hover-animate mx-auto w-10/12 rounded-lg bg-white p-2 font-semibold text-black disabled:opacity-20;
  }
  .modal {
    @apply fixed z-50 flex flex-col bg-[#151617]/80 p-3 drop-shadow-lg backdrop-blur-lg;
  }
  .modal-overlay {
    @apply fixed inset-0 z-30 bg-black/30;
  }
}

.AvatarImage {
  border-radius: inherit;
}

button {
  outline: none;
}

.PrettyScrollBar ::-webkit-scrollbar {
  height: '8px';
  background-color: 'white';
  border-radius: '30px';
}

.PrettyScrollBar ::-webkit-scrollbar-thumb {
  height: '8px';
  background-color: 'gray';
  border-radius: '30px';
}

.aspect-product {
  aspect-ratio: 5/4;
}

.drop-shadow-top {
  -webkit-box-shadow: 2px -12px 6px -8px rgba(92, 92, 92, 0.3);
  -moz-box-shadow: 2px -12px 6px -8px rgba(92, 92, 92, 0.3);
  box-shadow: 2px -12px 6px -8px rgba(92, 92, 92, 0.3);
}

.post-pay-dialog-overlay[data-state='open'],
.filter-dialog-overlay[data-state='close'] {
  animation: fadeOut 300ms ease-in;
}
.shipping-dialog-content[data-state='open'] {
  transform: translateY(100%);
  animation: filter-slide-up 0.4s ease-in-out forwards;
}
.shipping-dialog-content[data-state='closed'] {
  transform: translateY(0%);
  animation: filter-slide-down 0.7s ease-in-out backwards;
}
.filter-dialog-content[data-state='open'] {
  transform: translateY(100%);
  animation: filter-slide-up 0.3s ease-in forwards;
}
.filter-dialog-content[data-state='closed'] {
  transform: translateY(0%);
  animation: filter-slide-down 0.7s ease-in-out backwards;
}
@keyframes shipping-slide-up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes shipping-slide-down {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100%);
  }
}
@keyframes filter-slide-up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes filter-slide-down {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100%);
  }
}

.post-pay-dialog-content[data-state='open'] {
  transform: translateY(100%);
  animation: post-pay-slide-up 0.55s cubic-bezier(0.175, 0.885, 0.32, 1.275)
    forwards;
}

@keyframes post-pay-slide-up {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  50% {
    transform: translateY(-50%);
    opacity: 0.5;
  }

  75% {
    transform: translateY(-10%);
    opacity: 0.7;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.post-pay-dialog-content[data-state='closed'] {
  transform: translateY(0);
  animation: slide-down 0.85s ease-in-out backwards;
}

@keyframes slide-down {
  0% {
    transform: translateY(12%);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

/* .max-h-collapse {
  max-height: 60vh;
}

@media screen and (min-width: 365px) {
  .max-h-collapse {
    max-height: 60vh;
  }
} */

.glow-white {
  box-shadow: 0 0 35px 2px rgba(255, 255, 255, 0.089);
}

.success-clouds {
  animation: glowSuccess 50s infinite alternate;
}

@keyframes glowSuccess {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(180deg);
  }
}

.chat-background {
  background-image: linear-gradient(
    to top,
    #d5d4d0 0%,
    #d5d4d0 1%,
    #eeeeec 31%,
    #efeeec 75%,
    #e9e9e7 100%
  );
}

.main-skeleton-loader {
  position: relative;
  background-color: #333;
  overflow: hidden;
}
.skeleton-loader {
  position: relative;
  background-color: #444;
  overflow: hidden;
}

/* Skeleton Animation */
.skeleton-loader::after,
.main-skeleton-loader::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.1),
    transparent
  );
  animation: loading 1.5s infinite;
}

@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
